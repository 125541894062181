<template>
    <div class="tooltip-icon">
        <Tooltip type="dark">
            <template #reference>
                <svg class="icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM3.69525 3.7425C3.48175 4.0645 3.375 4.382 3.375 4.695C3.375 4.8475 3.4425 4.989 3.5775 5.1195C3.7125 5.25 3.87775 5.315 4.07325 5.3145C4.40575 5.3145 4.6315 5.128 4.7505 4.755C4.8765 4.3985 5.0305 4.1285 5.2125 3.945C5.3945 3.762 5.678 3.6705 6.063 3.6705C6.392 3.6705 6.66075 3.76125 6.86925 3.94275C7.07725 4.12475 7.18125 4.34775 7.18125 4.61175C7.18216 4.7442 7.14689 4.87437 7.07925 4.98825C7.00976 5.10385 6.925 5.20956 6.82725 5.3025C6.66955 5.44692 6.50747 5.58648 6.34125 5.721C6.08625 5.9325 5.88325 6.115 5.73225 6.2685C5.58225 6.4225 5.4615 6.60075 5.37 6.80325C5.1285 7.737 6.3825 7.812 6.672 7.14525C6.707 7.08125 6.76025 7.01025 6.83175 6.93225C6.90375 6.85475 6.99925 6.76475 7.11825 6.66225C7.4217 6.40952 7.72026 6.15098 8.01375 5.88675C8.17975 5.73375 8.323 5.55125 8.4435 5.33925C8.56798 5.11354 8.63059 4.85895 8.625 4.60125C8.625 4.24525 8.519 3.91525 8.307 3.61125C8.0955 3.30675 7.7955 3.06625 7.407 2.88975C7.0185 2.71325 6.5705 2.625 6.063 2.625C5.517 2.625 5.03925 2.73075 4.62975 2.94225C4.22025 3.15375 3.90875 3.421 3.69525 3.7425ZM5.30025 9.0525C5.30025 9.25141 5.37927 9.44218 5.51992 9.58283C5.66057 9.72348 5.85134 9.8025 6.05025 9.8025C6.24916 9.8025 6.43993 9.72348 6.58058 9.58283C6.72123 9.44218 6.80025 9.25141 6.80025 9.0525C6.80025 8.85359 6.72123 8.66282 6.58058 8.52217C6.43993 8.38152 6.24916 8.3025 6.05025 8.3025C5.85134 8.3025 5.66057 8.38152 5.51992 8.52217C5.37927 8.66282 5.30025 8.85359 5.30025 9.0525Z"
                        fill="#A8B0BA"
                    />
                </svg>
            </template>

            <template>
                <slot />
            </template>
        </Tooltip>
    </div>
</template>

<script>
import Tooltip from "@/components/Elements/Tooltip.vue";

export default {
    name: "TooltipIcon",
    components: {Tooltip},
}
</script>

<style lang="scss">
.icon {
    &:hover > path {
        transition: 0.1s ease-in;
        fill: rgba(66, 67, 65, 1);
    }
}
</style>
